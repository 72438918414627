<template>
  <div class="nodata">
    <img src="@/assets/newImg/nodata.png" alt="" />
    <p>{{ $t('common').noData }}</p>
  </div>
</template>

<script>
export default {}
</script>

<style lang="less" scoped>
.nodata {
  text-align: center;
  margin-bottom: 20px;
  img {
    width: 60px;
    height: 60px;
    margin: 20px 0 5px;
  }
}
</style>
